import { useLocale } from 'next-intl';
import Head from 'next/head';
import { ReactNode } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

type Props = {
  children?: ReactNode;
};

export function DefaultLayout({ children }: Props) {
  const locale = useLocale();

  return (
    <div lang={locale} className='flex flex-col min-h-screen antialiased'>
      {/* Head/Meta tags */}
      <Head>
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <meta name='author' content='webitfactory' />
        <link rel='shortcut icon' href='/favicon-new.png' />
        <meta
          property='og:image'
          content='https://happymarmots.io/img/og-image.svg'
        />
        <meta
          property='og:description'
          content='Discover Happy Marmots NFTs and earn rewards with Outdoor Aficionados app'
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-F8225TX6D6');
            `,
          }}
        />
        <script
          async
          src='https://www.googletagmanager.com/gtag/js?id=G-F8225TX6D6'
        ></script>
      </Head>
      <Toaster position='top-right'>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <button onClick={() => toast.dismiss(t.id)}>X</button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <div className='flex flex-col items-center w-screen'>{children}</div>
    </div>
  );
}
