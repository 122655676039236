'use client';

import InfoModal from '../InfoModal';
import styles from './styles.module.css';
import OutlineButton from '@components/atoms/OutlineButton';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Header() {
  const { ref, inView } = useInView();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const t = useTranslations('mint');

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section className='relative flex flex-col items-center'>
      <div
        className={`py-9 px-6 w-full flex justify-center ${
          inView ? 'relative' : 'fixed top-0 left-0 z-50 show-from-above'
        }`}
        style={{
          background:
            'linear-gradient(180deg, #0A1F28 50.33%, rgba(10, 31, 40, 0.93) 83.13%, rgba(10, 31, 40, 0) 95.65%)',
        }}
      >
        <div className='w-full flex items-center justify-between max-w-[1100px]'>
          <div className='relative w-fit h-[56px] items-center hidden md:flex'>
            {
              <Image
                priority
                src={'/img/happy_marmots_logo.webp'}
                width={224}
                height={56}
                quality={100}
                alt='Happy marmots'
                className='!max-w-[224px] !w-[224px] !h-[56px]'
              />
            }
          </div>
          <div className='flex items-center justify-end w-full flex-col md:flex-row'>
            <a
              href='https://opensea.io/collection/happy-marmots-oaf'
              target='_blank'
              className='text-orange font-semibold'
            >
              Originals NFT - Opensea{' '}
            </a>
            <div className='italic text-orange font-semibold ml-1'>
              {' '}
              | Comic book | Puzzle | Token
            </div>
          </div>
        </div>
      </div>
      <div
        ref={ref}
        className='mt-24 mb-20 w-full max-w-[90%] md:max-w-[850px]'
      >
        <Image
          src='/img/header-illustration.webp'
          width={1000}
          height={600}
          className='!w-full !h-auto'
          alt='Happy marmots'
        />
        <div className='text-orange text-center text-[20px] md:text-[26px] mt-10 font-semibold'>
          First edition ORIGINALS: 2426 NFTS - Congratulations to the holders!
        </div>
      </div>
      <button>
        <Image
          src='/img/slide-down-button.svg'
          width={70}
          height={72}
          alt='Explore the rest of the page'
          className='floating-animation w-10 h-10 md:w-[70px] md:h-[70px]'
        />
      </button>
      <InfoModal show={isModalOpen} onClose={handleCloseModal}>
        <div className='h-full w-full flex flex-col items-center'>
          <h3 className={`text-orange ${styles.mintHeader} pt-5 pb-3 relative`}>
            <Image
              src='/img/closeButton.png'
              width={390}
              height={503}
              alt='Download our whitepaper'
              className={`w-[30px] sm:w-[30px] ${styles.closeButton} pt-5`}
              onClick={() => handleCloseModal()}
            />
            <Image
              src='/img/1.png'
              width={390}
              height={503}
              alt='Download our whitepaper'
              className={`w-[120px] sm:w-[120px] ${styles.flipped}`}
            />
            <p className='text-orange text-[60px] flex items-center pt-8'>
              {t('8')}
            </p>
            <Image
              src='/img/2.png'
              width={390}
              height={503}
              alt='Download our whitepaper'
              className='w-[120px] sm:w-[120px]'
            />
          </h3>
          <ul className='p-8'>
            {[1, 2, 3, 4, 5, 6, 7].map((index) => {
              return (
                <li
                  key={index}
                  className='text-[20px] leading-[48px] pl-1 text-white font-bold'
                >
                  <span className={styles.highlight}>{t(`${index}.1`)}</span>{' '}
                  {t(`${index}.2`)}
                </li>
              );
            })}
          </ul>
          <OutlineButton
            text={'Mint your NFT'}
            link='https://mint.happymarmots.io/'
            longerButton={false}
            className='xl:!text-[30px] !px-8 !py-4'
          />
        </div>
      </InfoModal>
    </section>
  );
}
