import { DefaultLayout } from '@components/layouts/default.layout';
import Contact from '@components/molecules/Contact';
import Footer from '@components/molecules/Footer';
import Header from '@components/molecules/Header';
import MarmotSwiper from '@components/molecules/MarmotSwiper';
import Mint from '@components/organisms/Mint';
import Head from 'next/head';
import { ReactElement } from 'react';

export default function Home() {
  return (
    <>
      <Head>
        <title>Happy Marmots</title>
        <meta
          name='description'
          content='Join us on a journey where you&aposll earn rewards, unlock unique experiences and create unforgettable memories in the great outdoors'
        />
        <link rel='icon' href='/favicon-new.png' />
      </Head>

      <div className='max-width-container -mb-6 lg:-mb-28 z-[50]'>
        <Header />
        <MarmotSwiper />
      </div>
      <Mint />
      <div className='max-width-container -mt-12 lg:-mt-28 z-[29]'>
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export async function getStaticProps(context: any) {
  return {
    props: {
      // You can get the messages from anywhere you like. The recommended
      // pattern is to put them in JSON files separated by locale and read
      // the desired one based on the `locale` received from Next.js.
      messages: (await import(`../messages/${context.locale}.json`)).default,
    },
  };
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};
