'use client';

import Image from 'next/image';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Box({
  count,
  setSelectedBox,
}: {
  open: boolean;
  count: number;
  setSelectedBox?: any;
}) {
  const boxRef = useRef<any>();
  const [ref, inView] = useInView({ threshold: 1 });

  useEffect(() => {
    inView
      ? boxRef.current?.classList.add('shake-animation')
      : boxRef.current?.classList.remove('shake-animation');

    setTimeout(() => {
      boxRef.current?.classList.remove('shake-animation');
    }, 1000);
  }, [inView]);

  const handleOpenModal = () => {
    setSelectedBox(4 - count + 1);
  };

  const getSize = () => {
    if (count === 3 || count === 2) return 120;

    if (count === 1) return 100;

    return 140;
  };

  return (
    <section ref={ref}>
      <div onClick={() => handleOpenModal()} className='cursor-pointer'>
        <div className='relative flex flex-col items-center'>
          <Image
            ref={boxRef}
            src={`/img/mint/stone-${count}.svg`}
            width={70}
            height={100}
            alt='Prize box'
            className={`translate-y-[20px] ${count === 1 ? '-ml-4' : ''}`}
          />
          <Image
            src={`/img/mint/box-${count}.svg`}
            width={getSize()}
            height={144}
            alt='Prize box'
            className='relative z-[10]'
          />
        </div>
      </div>
    </section>
  );
}
