import Image from 'next/image';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function MarmotSwiper() {
  const images = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
  ];

  return (
    <section className='mt-[134px] md:mt-[120px] xl:mt-[88px] relative py-1'>
      <div
        className='absolute -left-1 -top-16 lg:top-4 xl:top-7 h-full w-[70px] md:w-[90px] z-10'
        style={{
          transform: 'rotate(-3deg)',
          background:
            'linear-gradient(90deg, #0A1F28 0%, rgba(10, 31, 40, 0) 100%)',
        }}
      ></div>
      <div
        className='absolute -right-1 -top-16 lg:-top-5 xl:-top-7 h-full w-[70px] md:w-[90px] z-10'
        style={{
          transform: 'rotate(-3deg)',
          background:
            'linear-gradient(270deg, #0A1F28 0%, rgba(10, 31, 40, 0) 100%)',
        }}
      ></div>
      <Swiper
        slidesPerView={'auto'}
        grabCursor={true}
        spaceBetween={'4px'}
        loop={true}
        speed={3000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        modules={[FreeMode, Autoplay]}
        initialSlide={0}
        className='!w-full'
        style={{ transform: 'rotate(-3deg)' }}
      >
        {images.map((name, index) => {
          return (
            <SwiperSlide key={index} className='!w-fit !h-fit'>
              <Image
                src={`/img/marmots/${name}.png`}
                width={195}
                height={240}
                alt='Our marmots'
                className='w-[112px] md:w-[195px]'
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className='text-orange text-center text-[20px] md:text-[26px] mt-10 md:mt-24 font-semibold'>
        Happy Marmots EVOLUTION! Coming soon!{' '}
      </div>
    </section>
  );
}
