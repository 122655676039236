import Image from 'next/image';
import { useEffect, useState } from 'react';

export default function ProgressBar() {
  const total = 8848;
  const sold: string = '2426';

  const breakpoints: { [key: string]: number } = {
    gold: 8848,
    silver: 6961,
    bronze: 4806,
    wood: 2544,
  };
  const margins: { [key: string]: number } = {
    gold: 65,
    silver: 55,
    bronze: 30,
    wood: 125,
  };
  const currentStage = '';

  const [isIphone, setIsIphone] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (navigator) {
        setIsIphone(navigator.userAgent.toLowerCase().includes('iphone'));
        console.log(navigator.userAgent);
      }
    }, 500);
  }, []);

  return (
    <section className='relative flex flex-col items-center'>
      <div className='relative z-20 -mb-[65px]'>
        <Image
          src='/img/mint/progress-bar/board-desktop.svg'
          width={200}
          height={60}
          alt='Mint your NFT in this phase'
        />
        <p
          className={`absolute text-[#54341A] left-0 right-0 ${
            isIphone ? 'top-[0%]' : 'top-[20%]'
          } mx-auto -rotate-[8deg] text-center font-londrina`}
        >
          <span
            className={isIphone ? 'text-[8px] md:text-[40px]' : 'text-[40px]'}
          >
            {sold}
          </span>
          <span
            className={isIphone ? 'text-[4px] md:text-[24px]' : 'text-[24px]'}
          >
            /{total}
          </span>
        </p>
      </div>
      <div className='relative flex h-fit !w-[85px] justify-center overflow-hidden'>
        <Image
          src='/img/mint/progress-bar/3-new.svg'
          width={105}
          height={600}
          alt='How many NFTs had been minted'
          className='relative z-10 !h-auto !w-[120px]'
        />
        <div className='absolute bottom-[30px] h-[90%] w-[40%] overflow-hidden rounded-[8px] bg-[#AE9037]'>
          <img
            src='/img/mint/progress-bar/1-new.svg'
            alt='How many NFTs had been minted'
            className='!h-auto w-[36.19px] max-w-[unset]'
            style={{
              transform: `translateY(${99 - (parseInt(sold) * 100) / total}%)`,
            }}
          />
        </div>
        <Image
          src='/img/mint/progress-bar/4.svg'
          width={60}
          height={450}
          alt='How many NFTs had been minted'
          className='absolute top-[19px] z-20 !h-auto !w-[27.5px]'
        />
      </div>
      <div className='absolute bottom-0 right-0 flex h-full flex-col justify-end'>
        {Object.entries(breakpoints).map(([key, value], index) => {
          return (
            <Image
              key={key}
              src={
                breakpoints[key] >= parseInt(sold)
                  ? `/img/mint/progress-bar/closed-${key}.svg`
                  : `/img/mint/progress-bar/open-${key}.svg`
              }
              width={currentStage !== key ? 60 : 77}
              height={currentStage !== key ? 60 : 77}
              alt='New stage can be unlocked soon'
              style={{ marginBottom: `${margins[key]}%` }}
            />
          );
        })}
      </div>
    </section>
  );
}
