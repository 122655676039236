'use client';

import Box from '@components/molecules/Box';
import ProgressBar from '@components/molecules/ProgressBar';
import { useIsMobile } from '@hooks/usIsMobile';
import Image from 'next/image';

export default function Mint() {
  const isMobile = useIsMobile();

  return (
    <section
      className={'w-full relative'}
      style={{
        background:
          'linear-gradient(#6BCCFF 0%, #2187A8 80%, #d4b272 81%,  #d4b272 100%)',
        zoom: isMobile ? '0.2' : '1',
      }}
    >
      <Image
        src='/img/mint/fulgi.svg'
        fill
        alt='Snow'
        className='z-10 !h-auto !w-full'
      />
      <div className='relative flex w-full flex-col items-center justify-end overflow-y-auto overflow-x-hidden'>
        <Image
          src='/img/mint/S4-S5.svg'
          fill
          alt='Road'
          className='object-fit !bottom-0 !h-full !w-full'
        />
        <Image
          src='/img/mint/elemente-s5.svg'
          fill
          alt='Happy marmots ecosystem'
          className='!relative z-20 !h-auto w-full max-w-[62%] lg:max-w-[90%] translate-y-[52%] lg:translate-y-[30%] xl:min-w-[700px] xl:max-w-[55%] xl:translate-y-[37%] 2xl:max-w-[62%]'
        />
        <Image
          src='/img/mint/elemente-s4.svg'
          fill
          alt='Happy marmots ecosystem'
          className='!relative z-20 !h-auto lg:min-w-[1100px] -translate-x-[6%] -translate-y-[3%] lg:max-w-[80%] xl:max-w-[70%]'
        />

        <section className='absolute z-[20] right-[45%] lg:right-[40%] top-[30%] lg:top-[27%] 2xl:right-[42%] 2xl:top-[33%]'>
          <Box open={false} count={1} setSelectedBox={() => {}} />
        </section>

        <section className='absolute z-[20] bottom-[20%] lg:bottom-[15%] left-[35%] 2xl:left-[40%]'>
          <Box open={false} count={2} setSelectedBox={() => {}} />
        </section>
      </div>
      <div className='absolute bottom-0 h-[350px] lg:h-52 w-full bg-primary z-20'></div>
      <div className='absolute top-0 h-[200px] w-full bg-primary z-20'></div>
      <div className='absolute left-[10%] top-[35%] z-[20]'>
        <ProgressBar />
      </div>
    </section>
  );
}
