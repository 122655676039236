import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useState } from 'react';

export default function Contact() {
  const t = useTranslations();
  const [hoveredElement, setHoveredElement] = useState(-1);

  return (
    <section className='flex flex-col items-center mb-[72px] md:mb-40'>
      <h1 className='mb-5 text-orange text-center text-[26px] leading-[32px] md:leading-[46px] md:text-[40px] font-semibold'>
        {t('contactTitle')}
      </h1>
      <div className='flex gap-4 items-center'>
        <div className='relative w-fit h-fit pt-4 pb-4'>
          <a
            href={'https://discord.com/invite/happymarmots'}
            target='_blank'
            className='py-14 px-10 text-secondary text-[32px] font-londrina font-normal z-[15] relative w-[100px] h-[100px] md:w-[162px] md:h-[162px] block'
          >
            <Image
              onMouseEnter={() => setHoveredElement(0)}
              src='/img/discord-orange.svg'
              fill
              alt='Discord social media'
              className='!w-full !h-full !max-h-full z-10'
            />
            <Image
              onMouseLeave={() => setHoveredElement(-1)}
              src='/img/discord-hovered.svg'
              fill
              alt='Discord social media'
              className={`absolute top-[22px] !w-full !h-full ${
                hoveredElement === 0 ? 'z-20' : 'z-0'
              }`}
            />
          </a>
        </div>

        <div className='relative w-fit h-fit pt-4 pb-4'>
          <a
            href={'https://twitter.com/happy_marmots'}
            target='_blank'
            className='py-10 px-10 text-secondary text-[32px] font-londrina font-normal z-10 relative w-[100px] h-[100px] md:w-[162px] md:h-[162px] block'
          >
            <Image
              onMouseEnter={() => setHoveredElement(2)}
              src='/img/twitter-orange.svg'
              fill
              alt='Twitter social media'
              className='!w-full !h-full !max-h-full z-10'
            />
            <Image
              onMouseLeave={() => setHoveredElement(-1)}
              src='/img/twitter-hovered.svg'
              fill
              alt='Twitter social media'
              className={`absolute top-[22px] !w-full !h-full ${
                hoveredElement === 2 ? 'z-20' : 'z-0'
              }`}
            />
          </a>
        </div>
      </div>
    </section>
  );
}
